import * as React from "react";
import { VoiceOfTheCustomer as VoiceOfTheCustomerComponent } from "@shared-ui/customer-voice-of-the-customer";
import { UitkSpacing } from "@egds/react-core/spacing";
export const VoiceOfTheCustomer: React.FC = () => (
  <UitkSpacing margin={{ medium: { blockstart: "six" } }}>
    <div data-stid="shared-ui-voice-of-the-customer">
      <VoiceOfTheCustomerComponent inputs={{}} />
    </div>
  </UitkSpacing>
);
export default VoiceOfTheCustomer;
